//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: 'DealerLocatorTeaser',
    props: ['data'],
    data() {
        return {
            isLoadingMaps: true,
            location: '',
            countries: [],
            country: null,
        };
    },
    computed: {
        ...mapGetters(['getStoredSiteProperties']),
        showContact() {
            return this.data?.showContact ?? false;
        },
        contactLink() {
            const contactPageId = this.getStoredSiteProperties?.contactform_page?.id;

            if (!contactPageId) return null;

            return this.$getLocalizedSlug(contactPageId);
        },
        searchLink() {
            const searchPageId = this.getStoredSiteProperties?.merchant_search_page?.id;

            if (!searchPageId) return null;

            return `${this.$getLocalizedSlug(searchPageId)}?place_id=${this.location}&country=${this.country}`;
        },
    },
    mounted() {
        const country = navigator.language.split('-')[0].toUpperCase();
        if(this.countries.find((o) => o.value === country)) {
            this.country = country;
        }
        this.isLoadingMaps = true;
        if (process.client && process.env.NUXT_ENV_GOOGLE_MAPS_KEY !== '') {
            this.$initGoogleMaps(process.env.NUXT_ENV_GOOGLE_MAPS_KEY).then(() => {
                this.isLoadingMaps = false;
            });
        }
    },
    methods: {
        cleanTel(tel) {
            return tel.replace(/[^\d\+]*/g, '');
        },
    },
    async fetch() {
        try {
            const data = await this.$nuxt.context.app.api.Merchant.list(this.$i18n.locale);
            let regionNames = new Intl.DisplayNames([this.$i18n.locale], {type: 'region'});

            const countries = data.merchants?.map((o) => o.country).filter(Boolean); // Filter out falsy values
            if (countries && countries.length) {
                this.countries = [...new Set(countries)]
                    .map((key) => {
                        try {
                            // Validate the region code before attempting to use it
                            if (!key) throw new Error(`Invalid country code: ${key}`);
                            return {
                                value: key,
                                text: key ? regionNames.of(key) : key,
                            };
                        } catch (e) {
                            console.error(`Error processing country code '${key}': ${e.message}`);
                            return null; // Return null for invalid entries
                        }
                    })
                    .filter(Boolean) // Remove null entries resulting from errors
                    .sort((a, b) => a.text.localeCompare(b.text));
            }
        } catch (e) {
            console.error(e);
        }
    },
};
