import { render, staticRenderFns } from "./index.vue?vue&type=template&id=25789826&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderComponent: require('/usr/src/app/components/HeaderComponent.vue').default,HeaderTouchComponent: require('/usr/src/app/components/HeaderTouchComponent.vue').default,Breadcrumb: require('/usr/src/app/components/Breadcrumb.vue').default,Product: require('/usr/src/app/components/Product/Product.vue').default,Merchant: require('/usr/src/app/components/Merchant/Merchant.vue').default,PageRelated: require('/usr/src/app/components/PageRelated.js').default,PageElement: require('/usr/src/app/components/PageElement.js').default,NewsletterSubscription: require('/usr/src/app/components/Partials/Newsletter/NewsletterSubscription.vue').default,FooterComponent: require('/usr/src/app/components/Footer/FooterComponent.vue').default,FooterTouchComponent: require('/usr/src/app/components/FooterTouchComponent.vue').default,StageModal: require('/usr/src/app/components/Configurator/components/StageModal.vue').default})
