//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
import { mapGetters } from 'vuex';
Swiper.use([Navigation, Pagination, Scrollbar]);

function showNavigation(swiper) {
    const { isBeginning, isEnd } = swiper;

    if (isBeginning && isEnd) {
        swiper.el.classList.add('reference-teaser-slider__slider--no-navigation');
    } else {
        swiper.el.classList.remove('reference-teaser-slider__slider--no-navigation');
    }
}

export default {
    name: 'ReferenceTeaserSlider',
    components: {},
    props: ['data'],
    mixins: [require('../../../mixins/product_range.js').default],
    data() {
        return {
            productItems: [],
        };
    },
    async fetch() {
        let rel = await this.$nuxt.context.app.api.Reference.loadReferenceRelated(this.data.id);
        if (rel.products) {
            for (let item of rel.products) {
                if (item && this.isInProductRange(item)) {
                    this.productItems.push(item);
                }
            }
        }
    },
    computed: {
        ...mapGetters(['getStoredPage', 'getStoredSiteProperties']),
        header() {
            return {
                style: 'inspiration',
                roofline: this.data?.referencesroofline,
                headline: this.data?.referencesheadline,
            };
        },
        link() {
            const href = this.data?.overviewhref;
            if (!href) return null;
            const { path, title, target, text } = href;
            return { path, title, target, text };
        },
    },
    mounted() {
        const gallery = new Swiper('.reference-teaser-slider__slider--slide', {
            spaceBetween: 5,
            slidesPerView: 'auto',
            loop: false,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            speed: 750,
            a11y: true,
            breakpoints: {
                768: {
                    spaceBetween: 30,
                },
            },
            navigation: {
                nextEl: '.reference-teaser-slider__button--next',
                prevEl: '.reference-teaser-slider__button--prev',
                disabledClass: 'reference-teaser-slider__button--disabled',
                dragSize: 100,
            },
            pagination: {
                el: '.reference-teaser-slider__pagination',
                type: 'fraction',
            },
            scrollbar: {
                el: '.reference-teaser-slider__scrollbar',
                dragClass: 'reference-teaser-slider__scrollbar--drag',
                hide: false,
            },
            on: {
                init: function () {
                    showNavigation(this);
                },
                resize: function () {
                    showNavigation(this);
                },
            },
        });
    },
    methods: {
        getImage(reference, index, type) {
            return reference?.images?.[index]?.[type];
        },
    },
};
