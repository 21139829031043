//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const MODAL_SIZE_SMALL = 'small';
const MODAL_SIZE_MEDIUM = 'medium';

export default {
    name: 'StageModal',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        headline: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: MODAL_SIZE_MEDIUM,
            validator: v => [MODAL_SIZE_SMALL, MODAL_SIZE_MEDIUM].includes(v),
        },
    },
    data () {
        return {
            eid: `e${ this._uid }`,
            MODAL_SIZE_SMALL,
            MODAL_SIZE_MEDIUM,
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        handleEscKey (e) {
            if (e.key === 'Escape') {
                this.active && this.close();
            }
        },
    },
    mounted () {
        document.addEventListener('keydown', this.handleEscKey.bind(this));
    },
    beforeDestroy () {
        document.removeEventListener('keydown', this.handleEscKey.bind(this));
    }
};
