//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { mapGetters } from 'vuex';

export default {
    name: 'SubNavi',
    props: ['data', 'pdpProduct', 'landingPageUrl'],
    components: {
        Multiselect,
    },
    data() {
        return {
            subnaviFixed: false,
            isMobile: !!this.$mobileDetect.mobile(),
            details: null,
            isOpen: false,
            productInfo: null,
            product: null,
            selectValue: null,
            anchorNav: null,
        };
    },
    computed: {
        ...mapGetters(['getStoredSiteProperties']),
        isConfigurable() {
            return ['BW', 'DW', 'WT', 'FL', 'DF'].some((key) => this.product?.externalKey?.startsWith(key));
        },
        pdpHref() {
            let href = this.$localeSlug(this.getStoredSiteProperties.product_page);
            if (this.product?.slug) {
                return (href = href + '/' + this.product.slug);
            } else return null;
        },
        landingPage() {
            return !this.pdpProduct;
        },
        options() {
            let optionsArray = this.isConfigurable
                ? [
                      { label: this.$t('landingpage.navi.landing.mobile'), id: 0 },
                      { label: this.$t('landingpage.navi.pdp.mobile'), id: 1 },
                      { label: this.$t('landingpage.navi.configure'), id: 2 },
                  ]
                : [
                      { label: this.$t('landingpage.navi.landing.mobile'), id: 0 },
                      { label: this.$t('landingpage.navi.pdp.mobile'), id: 1 },
                  ];
            return optionsArray;
        },
    },
    updated() {
        this.selectValue = this.landingPage ? this.$t('landingpage.navi.landing.mobile') : this.$t('landingpage.navi.pdp.mobile');
    },
    async fetch() {
        if (this.pdpProduct) {
            this.product = this.pdpProduct;
        } else {
            this.productInfo = this.$store.getters.getStoredPage.properties.find((o) => o.name === 'product');
            this.product = await this.$nuxt.context.app.api.product.getProductById(this.productInfo.object.id);
        }
    },
    mounted() {
        this.selectValue = this.landingPage ? this.$t('landingpage.navi.landing.mobile') : this.$t('landingpage.navi.pdp.mobile');

        const landingStage = document?.getElementsByClassName('landing-stage')[0] ?? null;
        if (landingStage) {
        }
        this.landingHeader(landingStage);
        this.anchorNav = document?.getElementsByClassName('anchor-nav')[0] ?? null;
    },
    methods: {
        landingHeader(landingStage) {
            const header = document.getElementsByClassName('header')[0];
            let subnaviPos;
            this.observeElement(landingStage);
            window.addEventListener('scroll', () => {
                if (!header || !this.$refs.subnavi.getBoundingClientRect()) return;
                if (window.scrollY > 5) {
                    const headerHeight = header.getBoundingClientRect().height;
                    header.style.top = `-${headerHeight}px`;
                    if (this.pdpProduct) {
                        this.$refs.subnavi.style.top = '0px';
                    }
                } else {
                    header.style.top = '0px';
                    this.subnaviFixed = false;
                }
                this.$nextTick(() => {
                    subnaviPos = this.$refs.subnavi.getBoundingClientRect().top;
                    if (subnaviPos <= 0) {
                        this.subnaviFixed = true;
                    }
                });
            });
        },
        onOpen() {
            this.isOpen = true;
        },
        observeElement(element) {
            if (!element) return;
            const stageInView = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.subnaviFixed = false;
                    }
                });
            };
            let observer = new IntersectionObserver(stageInView);
            observer.observe(element);
        },
        dispatchAction(action) {
            if (action.id === 0) {
                if (this.landingPageUrl) {
                    window.location = this.landingPageUrl;
                } else return;
            } else if (action.id === 1) {
                window.location = this.pdpHref;
            } else {
                window.location = `${this.$localeSlug(this.$store.state.siteProperties.configurator_page)}?product=${this.product.id}`;
            }
        },
    },
};
