//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PRODUCT_CATEGORY_TYPE_EQUIPMENT, PRODUCT_CATEGORY_TYPE_BATHTUB, PRODUCT_CATEGORY_TYPE_FURNITURE, PRODUCT_CATEGORY_TYPE_SHOWERSURFACE, PRODUCT_CATEGORY_TYPE_SHOWERTILE } from '../../constants/index';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    name: 'ProductSelectVariant',
    components: { Multiselect },
    props: {
        variants: {
            type: Array,
        },
        isDisabled: {
            type: Boolean,
        },
        hasSubNavi: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['getStoredProductCategoryType', 'getStoredProductVariant', 'getStoredProductVariants', 'getStoredProductCategoryTypeSegment']),
        variantsSorted() {
            return [...this.variants].sort((a, b) => a.prio - b.prio);
        },
    },
    methods: {
        ...mapActions(['setStoredProductVariant']),
    },
    watch: {
        selected(val) {
            this.$emit('input', val);
        },
    },
    data() {
        return {
            FURNITURE: PRODUCT_CATEGORY_TYPE_FURNITURE,
            EQUIPMENT: PRODUCT_CATEGORY_TYPE_EQUIPMENT,
            BATHTUB: PRODUCT_CATEGORY_TYPE_BATHTUB,
            SHOWERSURFACE: PRODUCT_CATEGORY_TYPE_SHOWERSURFACE,
            SHOWERTILE: PRODUCT_CATEGORY_TYPE_SHOWERTILE,
            selected: null,
            disabled: null,
            openDirection: !!this.$mobileDetect.mobile() || !this.hasSubNavi ? 'default' : 'bottom',
        };
    },
    created() {
        this.selected = { ...this.getStoredProductVariant };
        this.disabled = this.getStoredProductVariants?.length <= 1;
    },
};
