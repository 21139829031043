import { render, staticRenderFns } from "./ReferenceTeaserSlider.vue?vue&type=template&id=02f785c3&scoped=true&"
import script from "./ReferenceTeaserSlider.vue?vue&type=script&lang=js&"
export * from "./ReferenceTeaserSlider.vue?vue&type=script&lang=js&"
import style0 from "./ReferenceTeaserSlider.vue?vue&type=style&index=0&id=02f785c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f785c3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndentedContent: require('/usr/src/app/components/Partials/IndentedContent.vue').default,ProductTeaser: require('/usr/src/app/components/Partials/ProductTeaser/ProductTeaser.vue').default,TextBlock: require('/usr/src/app/components/Partials/TextBlock.vue').default,ReferenceTeaserSliderImage: require('/usr/src/app/components/Partials/Reference/ReferenceTeaserSliderImage.vue').default})
