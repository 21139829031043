//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: 'SocialMediaList',
    props: ['socialmedia'],
    computed: {
        ...mapGetters(['getStoredSiteProperties']),
        sm() {
            return this.getStoredSiteProperties?.footer?.socialmedia ?? [];
        },
    },
};
