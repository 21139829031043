//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageCopyright from '../Partials/ImageCopyright.vue';
import ProductDefinitionList from './ProductDefinitionList.vue';
import ProductDataTable from './ProductDataTable.vue';
import IndentedContent from '../Partials/IndentedContent.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductTecDetails',
    components: {IndentedContent, ProductDataTable, ProductDefinitionList, ImageCopyright},
    props: ['keys'],
    data() {
        return {
            imagePath: this.$config.pimcoreUrl,
            tableData: null,
        }
    },
    computed: {
        ...mapGetters([
            'getStoredProductVariant',
            'getStoredProduct'
        ]),
        variant() {
            return this.getStoredProductVariant || {};
        },
        product() {
            return this.getStoredProduct || {};
        },
        images() {
            let images = this.variant?.sketchesImages?.map((image, index) => {
                return {
                    subtitle: image.localeImage.localeSubtitle,
                    assetKey: image.localeImage.localeAssetKey,
                    ...this.$mapImageData(image.localeImage),
                }
            });

            if (!images) {
                images = [];
            }

            for(const image of this.product.localeImages.filter(i => i.localeImage.localeAssetKey === 'A_PIM_Zeichnung_Zubehör')) {
                images.push({
                    subtitle: image.localeImage.localeSubtitle,
                    assetKey: image.localeImage.localeAssetKey,
                    ...this.$mapImageData(image.localeImage),
                })
            }
            for(const image of this.product.images.filter(i => i.assetKey === 'A_PIM_Zeichnung_Zubehör')) {
                images.push({
                    assetKey: image.assetKey,
                    ...this.$mapImageData(image.gallery),
                })
            }

            return images;
        },
    },
    methods: {
        updateDataTable() {
            try {
                const td = this.product.variants.map(v => JSON.parse(v.drawingMeasure));
                this.tableData = td.filter(i => i !== null);
                if (!Object.keys(this.tableData[0]).some(i => this.tableData[0][i] !== null) ) {
                    this.tableData = null;
                }

            } catch(e) {
                console.warn('ProductTecDetail, created, tableData', e);
            }
        }
    },
    mounted() {
        this.updateDataTable();
    }
};
