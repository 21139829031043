import { render, staticRenderFns } from "./ProductTeaserSelection.vue?vue&type=template&id=023aa4cf&"
import script from "./ProductTeaserSelection.vue?vue&type=script&lang=js&"
export * from "./ProductTeaserSelection.vue?vue&type=script&lang=js&"
import style0 from "./ProductTeaserSelection.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductTeaser: require('/usr/src/app/components/Partials/ProductTeaser/ProductTeaser.vue').default})
