//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'

export default {
    name: "FormAutocomplete",
    components: {
        Multiselect
    },
    props: ['placeholder', 'value', 'options', 'white', 'label', 'required', 'id', 'error'],
    data() {
        return {
            items: [],
            selected: null,
            searchGeoLocationDebounce: null,
            isLoadingLocation: false,
        }
    },
    watch: {
        selected(item) {
            this.$emit('input', item ? item.value : '');
        },
        value(val) {
            this.handleValue(val);
        }
    },
    methods: {
        handleValue(val) {
            this.selected = this.options?.find(o => o.value === val) || '';
        },
    },
    mounted() {
        //fix vue multiselect autocomplete="nope" bug
        this.$refs.multiselectRef.$refs.search.setAttribute("autocomplete", "off");
        this.items = this.options;
        this.selected = this.options?.find(o => o.value === this.value) || '';
    }
}
