export function getMetaObject(page) {
    const { meta_description } = page;

    const metaObject = [...getTwitterMetaObject(page), ...getFacebookMetaObject(page)];

    if (meta_description) {
        metaObject.push(createMetaItem('description', meta_description));
    }

    return metaObject;
}

export function getTwitterMetaObject(page) {
    const { twitter_title, twitter_description, twitter_image, meta_title } = page;
    const metaObject = [];

    if (twitter_title) {
        metaObject.push(createMetaItem('twitter:title', twitter_title));
    }
    if (twitter_description) {
        metaObject.push(createMetaItem('twitter:description', twitter_description));
    }
    if (twitter_image) {
        metaObject.push(createMetaItem('twitter:image', twitter_image));
    }
    if (meta_title) {
        metaObject.push(createMetaItem('twitter:image:alt', meta_title));
    }

    return metaObject;
}

export function getFacebookMetaObject(page) {
    const { facebook_title, facebook_description, facebook_image, meta_title } = page;
    const metaObject = [];

    if (facebook_title) {
        metaObject.push(createMetaItem('og:title', facebook_title));
    }
    if (facebook_description) {
        metaObject.push(createMetaItem('og:description', facebook_description));
    }
    if (facebook_image) {
        metaObject.push(createMetaItem('og:image', facebook_image));
        metaObject.push(createMetaItem('og:image:secure_url', facebook_image));
    }
    if (meta_title) {
        metaObject.push(createMetaItem('og:image:alt', meta_title));
    }

    return metaObject;
}

function createMetaItem(name, content) {
    return { hid: name, name, content };
}
